import { MeasurementMetaLibraryData } from '../measurement_metas';
import { request } from '../request';

export interface MeasurementMetaSuggestionData extends MeasurementMetaLibraryData {
  suggestion_for_id: number;
}

export interface CountRequestResponse {
  count: number;
}

export const list = () =>
  request<MeasurementMetaSuggestionData[]>(
    'GET',
    `/api/v2/measurement_meta_suggestions/`,
    {},
    { injectTenant: true, version: 2 }
  );

export const remove = (id: string) =>
  request('DELETE', `/api/v2/measurement_meta_suggestions/${id}/`, {}, { injectTenant: true, version: 2 });

export const count = () =>
  request<CountRequestResponse>(
    'GET',
    `/api/v2/measurement_meta_suggestions/count/`,
    {},
    { injectTenant: true, version: 2 }
  );
