import * as ko from 'knockout';
import page from 'page';

import { ListLoader, ListLoaderDelegate } from '../../components/list_loader';
import {
  DataEntryVisitData,
  listDataEntryVisits,
  markDataEntryVisitCompleted,
  removeDataEntryVisit,
} from '../data_entry_api';
import { NO_PAGINATION } from '../../components/list_loader';
import { Action } from '../../components/basic_widgets';
import i18n from '../../i18n';
import { session } from '../../session';
import { openDataEntryEditVisitDate } from './data_entry_edit_visit_date';
import { uuid4 } from '../../utils/uuid';

const template = require('raw-loader!./data_entry_visits.html').default;

class DataEntryVisitsScreen implements ListLoaderDelegate<DataEntryVisitData> {
  pageSize = NO_PAGINATION;

  private trialId: string;
  private loader: ListLoader<DataEntryVisitData>;
  private siteId: string;
  private plotId: string;

  hideCompletedVisits = ko.observable<boolean>(false);

  constructor(params: { trialId: string }) {
    this.trialId = params.trialId;
    const urlSearchParams = new URLSearchParams(window.location.search);
    this.siteId = urlSearchParams.get('site_id');
    this.plotId = urlSearchParams.get('plot_id');
    this.hideCompletedVisits.subscribe(() => this.loader.refresh());
  }

  onReady(loader: ListLoader<DataEntryVisitData>): void {
    this.loader = loader;
  }

  fetch() {
    return listDataEntryVisits(this.trialId, this.hideCompletedVisits(), this.siteId);
  }

  instantiate(data: DataEntryVisitData) {
    return data;
  }

  getEditUrl(data: DataEntryVisitData) {
    let url = `/trials/${this.trialId}/observations/?sv_id=${data.sv_id ?? ''}&site_id=${
      data.site_id ?? ''
    }&visit_id=${data.id ?? ''}`;
    url += this.plotId ? `&plot_id=${this.plotId}` : '';
    return url;
  }

  canRemove(data: DataEntryVisitData) {
    return data.can_delete;
  }

  remove(id: string) {
    return removeDataEntryVisit(id);
  }

  getActions(data: DataEntryVisitData) {
    const res: Action[] = [];

    if (data.sv_id) {
      res.push({
        icon: 'today',
        title: i18n.t('Set visit date')(),
        cssClass: '',
        onClick: () => this.onSetVisitDate(data),
      });
    }
    res.push({
      icon: data.marked_completed ? 'remove_done' : 'done_all',
      title: data.marked_completed ? i18n.t('Mark as uncompleted')() : i18n.t('Mark as completed')(),
      cssClass: '',
      onClick: () => this.onMarkVisitCompleted(data),
    });
    if (data.can_add) {
      res.push({
        icon: 'content_copy',
        title: i18n.t('Duplicate')(),
        cssClass: '',
        onClick: () => {
          page(
            session.toTenantPath(
              `/trials/${this.trialId}/observations/?sv_id=${data.sv_id ?? ''}&site_id=${
                data.site_id ?? ''
              }&visit_id=${uuid4()}`
            )
          );
        },
      });
    }

    return res;
  }

  private async onMarkVisitCompleted(data: DataEntryVisitData) {
    this.loader.loading(true);
    markDataEntryVisitCompleted({ ...data, marked_completed: !data.marked_completed }).then(() => {
      this.loader.refresh();
    });
  }
  private async onSetVisitDate(data: DataEntryVisitData) {
    await openDataEntryEditVisitDate(this.trialId, data);
    this.loader.refresh();
  }
}

export const dataEntryVisits = {
  name: 'data-entry-visits',
  viewModel: DataEntryVisitsScreen,
  template,
};

ko.components.register(dataEntryVisits.name, dataEntryVisits);
