/// <reference path='../type_definitions/hopscotch.d.ts' />

import page from 'page';
import * as ko from 'knockout';
import i18n from '../i18n';

import { session } from '../session';
import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate, ListLoader } from '../components/list_loader';
import { Trial } from '../models/trial';
import { TrialState, getTrialStateChoices } from '../models/TrialState';
import * as trialsApi from '../api/trials';
import { countriesApi, CountryData, listForTrials } from '../api/countries';
import * as regionsApi from '../api/regions';
import * as usersApi from '../api/users';
import * as dimensionsApi from '../api/dimensions';
import * as cropsApi from '../api/crops';
import { Action } from '../components/basic_widgets';
import { startTour } from '../tour';
import { translate } from '../i18n_text';
import { createTrialPopup } from '../components/create_trial_popup';
import { DimensionData } from '../api/dimensions';
import { app } from '../app';
import { Deferred } from '../utils/deferred';
import {
  canCreateTemplate,
  canMakeTrialDraft,
  canEditTrial,
  weatherEnabled,
  canEnterObservationsForTrial,
  canEditTrialLimited,
  canExportTrial,
  trialEditableByUser,
} from '../permissions';
import { GeoJSON } from '../api/datasets';
import { ListHeaderAction } from '../components/list_header';
import { FilterDelegate, IdData } from '../components/list_filters';
import { deflateList } from '../api/serialization';
import { updateLocationWithQueryString, asArray, readFromLocalStorage } from '../utils';
import { TRIAL_TYPE_SLUG } from '../api/dimension_metas';
import { removeDialog } from '../components/remove_dialog';
import { confirmDialog } from '../components/confirm_dialog';
import { CustomerData, customersApi, PartnerData, partnersApi } from '../api/organizations';

let template = require('raw-loader!../../templates/trials.html').default;

const trialSortOrderStorageKey = 'trials-sort-order';
const trialSortDirectionStorageKey = 'trials-sort-direction';
const trialsFiltersStorageKey = 'trials-filters-v1'; // update when format is not compatible with existing saved formats

class TrialsScreen implements ListLoaderDelegate<trialsApi.TrialData, Trial> {
  pageSize = 25;

  mapVisualizationFeatureEnabled = session.tenant() && session.tenant().map_visualization_enabled;

  private listLoader: ListLoader<trialsApi.TrialData, Trial>;

  user = ko.observable<usersApi.UserData>(null);
  loading = ko.observable(true);
  loadingMap = ko.observable(false);
  trials = ko.observableArray<trialsApi.TrialData>(null);

  search = ko.observable('');
  searchPlaceholder = i18n.t('Search trials by name or code')();
  cropFilter = ko.observableArray<DimensionData>(null);
  countryFilter = ko.observableArray<CountryData>(null);
  regionFilter = ko.observableArray<regionsApi.RegionData>(null);
  ownerFilter = ko.observableArray<usersApi.UserData>(null);
  customerFilter = ko.observableArray<CustomerData>(null);
  partnerFilter = ko.observableArray<PartnerData>(null);
  trialTypeFilter = ko.observableArray<DimensionData>(null);
  trialStateFilter = ko.observableArray<IdData>(null);
  showArchived = ko.observable(false);
  sortBys = ko.observableArray([ko.observable<string>('scheduled_planting_date')]);
  sortDirection = ko.observable('desc');

  private trialStateChoices = getTrialStateChoices();

  newFilters: FilterDelegate[] = [
    { title: i18n.t('Crop')(), entities: this.cropFilter, list: cropsApi.list },
    {
      title: i18n.t('Country')(),
      entities: this.countryFilter,
      list: listForTrials,
    },
    {
      title: i18n.t('Region')(),
      entities: this.regionFilter,
      list: regionsApi.list,
    },
    {
      title: i18n.t('Owner')(),
      entities: this.ownerFilter,
      list: usersApi.list,
    },
    {
      title: i18n.t('Customer')(),
      entities: this.customerFilter,
      list: customersApi.list,
    },
    {
      title: i18n.t('Partner')(),
      entities: this.partnerFilter,
      list: (params) =>
        partnersApi.list({
          ...params,
          ...(this.showArchived() ? {} : { archived: false }),
        }),
    },
    {
      title: i18n.t('Type')(),
      entities: this.trialTypeFilter,
      list: (params) => dimensionsApi.list(TRIAL_TYPE_SLUG, {}, params),
    },
    {
      title: i18n.t('State')(),
      entities: this.trialStateFilter,
      list: (params) => Promise.resolve(this.trialStateChoices),
    },
  ];

  showCreateButtonIndicator = ko.observable(false);
  template: boolean;
  listActions = ko.observableArray<ListHeaderAction>();

  constructor(params: { template: boolean; filters: trialsApi.TrialListFilters }) {
    const storageFilters = readFromLocalStorage(trialsFiltersStorageKey);
    let filters: trialsApi.TrialListFilters =
      storageFilters && Object.keys(params.filters).length === 0 ? storageFilters : params.filters;

    this.template = params.template;
    if (params.template) {
      this.sortBys([]);
    } else {
      let initialSortOrder =
        filters.sort_by || localStorage.getItem(trialSortOrderStorageKey) || 'scheduled_planting_date';
      if (initialSortOrder === 'scheduled_planting_date') {
        this.sortBys()[0]('scheduled_planting_date');
      } else {
        this.sortBys()[0]('name');
      }

      const initialSortDirection =
        filters.sort_direction || localStorage.getItem(trialSortDirectionStorageKey) || 'desc';
      this.sortDirection(initialSortDirection);
    }

    // The add button should not be visible in templates if the user is not
    // at least template editor.
    if (
      !session.isReadOnlyAdmin() &&
      ((!this.template && !session.isRestrictedManager()) || session.isAtLeastTemplateEditor())
    ) {
      this.listActions.push({
        title: i18n.t('Add')(),
        icon: 'add_circle',
        onClick: this.addTrial,
        tooltipTitle: this.template ? i18n.t('Add trial template')() : i18n.t('Add trial')(),
      });
    }
    if (!params.template) {
      if (this.mapVisualizationFeatureEnabled) {
        this.listActions.push({
          title: i18n.t('Map')(),
          icon: 'map',
          onClick: this.viewOnMap,
          loading: this.loadingMap,
          tooltipTitle: i18n.t('Sites map')(),
        });
      }
      this.listActions.push({
        title: i18n.t('Active')(),
        icon: 'playlist_play',
        href: '/trial_activity_status/',
        tooltipTitle: i18n.t('My Active Trials')(),
      });
      if (session.tenant()?.web_data_entry) {
        this.listActions.push({
          title: i18n.t('Data')(),
          icon: 'grid_on',
          href: '/data_entry/trials/',
          tooltipTitle: i18n.t('Data entry')(),
        });
      }
    }

    if (filters.show_archived === 'true') {
      this.showArchived(true);
    }
    if (filters.search) {
      this.search(filters.search);
    }
    this.search = this.search.extend({ rateLimit: 300 });

    let states = asArray(filters.states);
    let cropIds = asArray(filters.crop_ids);
    let cropsPromise =
      cropIds.length > 0 ? cropsApi.list({ ids: cropIds }) : Promise.resolve<cropsApi.CropData[]>([]);
    let countryIds = asArray(filters.country_ids);
    let countriesPromise =
      countryIds.length > 0 ? countriesApi.list({ ids: countryIds }) : Promise.resolve<CountryData[]>([]);
    let regionIds = asArray(filters.region_ids);
    let regionsPromise =
      regionIds.length > 0
        ? regionsApi.list({ ids: regionIds })
        : Promise.resolve<regionsApi.RegionData[]>([]);
    let userIds = asArray(filters.user_ids);
    let usersPromise =
      userIds.length > 0 ? usersApi.list({ ids: userIds }) : Promise.resolve<usersApi.UserData[]>([]);
    let customerIds = asArray(filters.customer_ids);
    let customersPromise =
      customerIds.length > 0 ? customersApi.list({ ids: customerIds }) : Promise.resolve<CustomerData[]>([]);
    let partnerIds = asArray(filters.partner_ids);
    let partnersPromise =
      partnerIds.length > 0 ? partnersApi.list({ ids: partnerIds }) : Promise.resolve<PartnerData[]>([]);
    let typeIds = asArray(filters.trial_type_ids);
    let typesPromise =
      typeIds.length > 0
        ? dimensionsApi.list(TRIAL_TYPE_SLUG, { ids: typeIds }, {})
        : Promise.resolve<DimensionData[]>([]);
    let countRecordsToReviewPromise = trialsApi.countRecordsToReview();

    Promise.all([
      cropsPromise,
      countriesPromise,
      regionsPromise,
      usersPromise,
      customersPromise,
      partnersPromise,
      typesPromise,
      usersApi.me(),
      countRecordsToReviewPromise,
    ]).then(
      ([crops, countries, regions, users, customers, partners, trialTypes, userData, recordsToReview]) => {
        if (crops) {
          this.cropFilter(crops);
        }
        if (countries) {
          this.countryFilter(countries);
        }
        if (regions) {
          this.regionFilter(regions);
        }
        if (users) {
          this.ownerFilter(users);
        }
        if (customers) {
          this.customerFilter(customers);
        }
        if (partners) {
          this.partnerFilter(partners);
        }
        if (trialTypes) {
          this.trialTypeFilter(trialTypes);
        }
        this.trialStateFilter(this.trialStateChoices.filter((state) => states.includes(state.id)));

        this.user(userData);
        if (!params.template && recordsToReview.count > 0) {
          this.listActions.splice(1, 0, {
            title: i18n.t('Review')(),
            chipText: recordsToReview.count.toString(),
            icon: 'rate_review',
            href: '/review/',
            tooltipTitle: i18n.t('Review {{count}} records', {
              count: recordsToReview.count,
            })(),
          });
        }
        this.loading(false);
      }
    );
  }

  canCreate = ko.pureComputed(() => {
    return !this.template || canCreateTemplate(this.user());
  });

  addTrial = () => {
    createTrialPopup({ template: this.template });
  };

  sortTable = (by: string) => {
    let params = new URLSearchParams(location.search);
    params.set('sort_by', by);
    let direction = params.get('sort_direction');

    if (!direction || direction == 'desc') {
      params.set('sort_direction', 'asc');
    } else if (direction == 'asc') {
      params.set('sort_direction', 'desc');
    }

    window.location.search = params.toString();
  };

  onReady(loader: ListLoader<trialsApi.TrialData, Trial>) {
    this.listLoader = loader;
    this.search.subscribe(() => this.listLoader.forceLoad());
    this.showArchived.subscribe(() => this.listLoader.forceLoad());
  }

  fetch(params: ListRequestParams) {
    let currentFilters = this.getFilters();
    updateLocationWithQueryString(currentFilters);
    const toSave = { ...currentFilters };
    delete toSave.template;
    delete toSave.sort_by;
    delete toSave.sort_direction;
    localStorage.setItem(trialsFiltersStorageKey, JSON.stringify(toSave));
    localStorage.setItem(trialSortOrderStorageKey, currentFilters.sort_by);
    localStorage.setItem(trialSortDirectionStorageKey, currentFilters.sort_direction);

    return trialsApi.list({ ...params, ...currentFilters }).then((data) => {
      if (params.offset === 0 && data.length === 1 && this.canCreate()) {
        for (let trialData of data) {
          if (translate(trialData.name_json).indexOf('[DEMO]') !== 0) {
            return data;
          }
        }

        // we only have the 1 demo trial
        this.showCreateButtonIndicator(true);
      }
      startTour();

      return data;
    });
  }

  private getFilters(): trialsApi.TrialListFilters {
    return {
      search: this.search(),
      crop_ids: deflateList(this.cropFilter),
      country_ids: deflateList(this.countryFilter),
      region_ids: deflateList(this.regionFilter),
      user_ids: deflateList(this.ownerFilter),
      customer_ids: deflateList(this.customerFilter),
      partner_ids: deflateList(this.partnerFilter),
      show_archived: this.showArchived() ? 'true' : 'false',
      trial_type_ids: deflateList(this.trialTypeFilter),
      states: deflateList(this.trialStateFilter),
      sort_by: this.template ? 'name' : this.sortBys()[0](),
      sort_direction: this.template ? '' : this.sortDirection(),
      template: this.template,
    };
  }

  instantiate(data: trialsApi.TrialData) {
    return new Trial(null, data);
  }

  remove(id: string) {
    return trialsApi.remove(id);
  }

  canRemove(trial: Trial) {
    // we use a more explicit remove action for trials
    return false;
  }

  makeDraft = (trial: Trial) => {
    trialsApi.makeDraft(trial.id()).then(() => {
      trial.state(TrialState.Draft);
    });
  };

  activate = (trial: Trial) => {
    trialsApi.activate(trial.id()).then(() => {
      trial.state(TrialState.Active);
    });
  };

  archive = (trial: Trial) => {
    trialsApi.archive(trial.id()).then(() => {
      trial.state(TrialState.Archived);
    });
  };

  setCompleted = async (trial: Trial) => {
    await confirmDialog(
      i18n.t('Set completed')(),
      [
        i18n.t(
          'You are about to complete your trial so it can be reviewed. Once \
          completed, data can not be entered anymore. You will still be able to \
          update the trial Owners and review / update observations in the \
          Overview menu.'
        )(),
        i18n.t([
          'please_confirm_complete_trial',
          'Please, confirm that you want to complete your trial now.',
        ])(),
      ],
      '',
      false,
      i18n.t('Yes, please complete')(),
      i18n.t('No, I need to check again')()
    );

    trialsApi.setCompleted(trial.id()).then(() => {
      trial.state(TrialState.Completed);
    });
  };

  setDataValidated = async (trial: Trial) => {
    await confirmDialog(
      i18n.t('Set data validated')(),
      [
        i18n.t(
          'You are about to validate your data so it can be processed. Once validated, the data can not be changed anymore.'
        )(),
        i18n.t([
          'please_confirm_validate_trial',
          'Please, confirm that you want to validate your data now.',
        ])(),
      ],
      '',
      false,
      i18n.t('Yes, please validate')(),
      i18n.t('No, I need to check again')()
    );

    trialsApi.setDataValidated(trial.id()).then(() => {
      trial.state(TrialState.DataValidated);
    });
  };

  makeTemplate = (trial: Trial) => {
    trialsApi.makeTemplate(trial.id()).then(() => {
      this.listLoader.items.remove(trial);
    });
  };

  switchToManualMode = async (trial: Trial) => {
    await confirmDialog(i18n.t('Switch trial to manual mode')(), [
      i18n.t('This operation cannot be undone.')(),
      i18n.t('Are you sure you want to continue?')(),
    ]);
    trialsApi.switchToManualMode(trial.id());
  };

  confirmRemove = (trial: Trial) => {
    let args = { trialName: translate(trial.nameJson()) };
    let msgs = [
      i18n.t(
        ['permanently_delete_trial', 'You are about to permanently delete the trial {{trialName}}.'],
        args
      )(),
      i18n.t('It will no longer be possible to view trial results or to enter new observations.')(),
      i18n.t('This operation cannot be undone.')(),
      i18n.t('Are you sure you want to continue?')(),
    ];

    removeDialog(args.trialName, msgs, () => trialsApi.remove(trial.id())).then(() => {
      this.listLoader.items.remove(trial);
    });
  };

  canEdit(trial: Trial): boolean {
    return canEditTrial(this.user(), trial);
  }

  // Can be edited by a restricted manager.
  canEditLimited(trial: Trial): boolean {
    return canEditTrialLimited(this.user(), trial);
  }

  canCopyTrial(trial: Trial): boolean {
    return trialEditableByUser(this.user(), trial);
  }

  canChangeState(trial: Trial, new_state: string): boolean {
    // Admin can't edit an archived trial, but can change its state back to active.
    if (this.canEdit(trial) || this.user().role === 'admin') {
      return true;
    }

    // Restricted manager can activate
    if (this.canEditLimited(trial) && new_state === TrialState.Active) {
      return true;
    }

    return false;
  }

  private canMakeDraft(trial: Trial): boolean {
    return canMakeTrialDraft(this.user(), trial);
  }

  getActions(trial: Trial) {
    let res: Action[] = [];
    let canEdit = this.canEdit(trial);
    let canEditLimited = this.canEditLimited(trial);

    if (this.canChangeState(trial, TrialState.Active)) {
      if (trial.canActivate()) {
        res.push({
          icon: 'sync',
          title: i18n.t('Activate')(),
          cssClass: '',
          onClick: () => {
            this.activate(trial);
          },
        });
      }
    }

    if (canEditLimited) {
      res.push({
        icon: 'group_add',
        title: i18n.t('Assign staff')(),
        cssClass: '',
        onClick: () => {
          location.href = session.toTenantPath(trial.assignStaffUrl());
        },
      });
    }
    if (canEditLimited) {
      res.push({
        icon: 'article',
        title: i18n.t('Documents')(),
        cssClass: '',
        onClick: () => {
          page(
            session.toTenantPath(
              `/${trial.template ? 'trial_templates' : 'trials'}/${trial.id()}/documents/`
            )
          );
        },
      });
    }

    if (!this.template) {
      res.push({
        icon: 'bar_chart',
        title: i18n.t('Visualize data')(),
        cssClass: '',
        onClick: () => {
          page(session.toTenantPath(trial.dashboardUrl()));
        },
      });
      if (canEnterObservationsForTrial(this.user(), trial)) {
        res.push({
          icon: 'assignment',
          title: i18n.t('Data entry')(),
          cssClass: '',
          onClick: () => {
            page(session.toTenantPath(`/data_entry/visits/${trial.id()}/`));
          },
        });
      }
      if (this.mapVisualizationFeatureEnabled) {
        res.push({
          icon: 'map',
          title: i18n.t('Map (Obs.)')(),
          cssClass: '',
          onClick: () => {
            page(session.toTenantPath(trial.dashboardMapUrl()));
          },
        });
      }
      if (weatherEnabled()) {
        res.push({
          icon: 'cloud',
          title: i18n.t('Weather status')(),
          cssClass: '',
          onClick: () => {
            page(session.toTenantPath(`/trials/${trial.id()}/weather_site_status/`));
          },
        });
      }
    }

    if (this.canChangeState(trial, TrialState.Completed) && trial.canSetCompleted()) {
      res.push({
        icon: 'done',
        title: i18n.t('Set completed')(),
        cssClass: '',
        onClick: () => {
          this.setCompleted(trial);
        },
      });
    }

    if (this.canChangeState(trial, TrialState.DataValidated) && trial.canSetDataValidated()) {
      res.push({
        icon: 'domain_verification',
        title: i18n.t('Set data validated')(),
        cssClass: '',
        onClick: () => {
          this.setDataValidated(trial);
        },
      });
    }

    if (session.tenant()?.manual_trials_enabled && canEdit && trial.editMode === 'library') {
      res.push({
        icon: 'developer_board_off',
        title: i18n.t('Manual mode')(),
        cssClass: '',
        onClick: () => {
          this.switchToManualMode(trial);
        },
      });
    }

    if (canExportTrial(trial)) {
      res.push({
        icon: 'file_download',
        title: i18n.t('Export')(),
        cssClass: '',
        onClick: () => {
          page(session.toTenantPath(trial.exportTrialUrl()));
        },
      });
    }

    if (this.template && canEdit) {
      res.push({
        icon: 'file_download',
        title: i18n.t('Define export')(),
        cssClass: '',
        onClick: () => {
          page(session.toTenantPath(trial.exportTrialUrl()));
        },
      });
    }

    if (canEnterObservationsForTrial(this.user(), trial)) {
      res.push({
        icon: 'file_upload',
        title: i18n.t('Import observations')(),
        cssClass: '',
        onClick: () => {
          page(session.toTenantPath(trial.importFactsUrl()));
        },
      });
    }

    if (
      canCreateTemplate(this.user()) &&
      !trial.template &&
      trial.isDraft() &&
      trial.editMode === 'manual'
    ) {
      res.push({
        icon: 'code',
        title: i18n.t('Turn into a template')(),
        cssClass: '',
        onClick: () => {
          this.makeTemplate(trial);
        },
      });
    }

    if (this.canMakeDraft(trial)) {
      res.push({
        icon: 'drafts',
        title: i18n.t('Make draft')(),
        cssClass: '',
        onClick: () => {
          this.makeDraft(trial);
        },
      });
    }

    if (canEdit) {
      if (trial.canArchive()) {
        res.push({
          icon: 'archive',
          title: i18n.t('Archive')(),
          cssClass: '',
          onClick: () => {
            this.archive(trial);
          },
        });
      }
    }

    if (this.canCopyTrial(trial)) {
      res.push({
        icon: 'content_copy',
        title: i18n.t('Copy')(),
        cssClass: '',
        onClick: () => {
          page(session.toTenantPath(trial.copyUrl()));
        },
      });
    }

    res.push({
      icon: 'file_download',
      title: i18n.t('Export plots')(),
      cssClass: '',
      onClick: () => {
        page(session.toTenantPath(trial.exportPlotsUrl()));
      },
    });
    res.push({
      icon: 'print',
      title: i18n.t('Print plots')(),
      cssClass: '',
      onClick: () => {
        page(session.toTenantPath(trial.printPlotsUrl()));
      },
    });
    if (!this.template) {
      res.push({
        icon: 'print',
        title: i18n.t('Print field book')(),
        cssClass: '',
        onClick: () => {
          page(session.toTenantPath(trial.fieldBookUrl()));
        },
      });
    }
    if (!trial.template && canEditLimited && !SERVER_INFO.SIMPLE_PERMISSIONS) {
      res.push({
        icon: 'security',
        title: i18n.t('Assign groups')(),
        cssClass: '',
        onClick: () => {
          page(session.toTenantPath(trial.assignGroupsUrl()));
        },
      });
    }

    if (canEdit) {
      res.push({
        icon: 'delete_outline',
        title: i18n.t('Delete')(),
        cssClass: '',
        onClick: () => {
          this.confirmRemove(trial);
        },
      });
    }

    return res;
  }

  viewOnMap = () => {
    this.loadingMap(true);

    trialsApi
      .siteLocations(this.getFilters())
      .then((data) => {
        this.loadingMap(false);
        this.openMap(data);
      })
      .catch((e) => {
        this.loadingMap(false);
        throw e;
      });
  };

  private openMap(locations: trialsApi.TrialSiteLocationData[]) {
    app.formsStackController.push({
      title: i18n.t('Trial sites')(),
      name: 'map',
      isBig: true,
      params: {
        value: getTrialLocationsMap(locations),
        result: new Deferred<{}>(),
      },
    });
  }
}

export let trials = {
  name: 'trials',
  viewModel: TrialsScreen,
  template: template,
};

ko.components.register(trials.name, trials);

export function getTrialLocationsMap(locations: trialsApi.TrialSiteLocationData[]): GeoJSON[] {
  const sitesLocations = locations.map((loc) => {
    let info = [{ title: i18n.t('Site')(), value: translate(loc.site_name_json) }];
    for (let trial of loc.trials) {
      info.push({
        title: i18n.t('Trial')(),
        value: translate(trial.name_json) + ', ' + i18n.t('Crop')() + ': ' + translate(trial.crop_name_json),
      });
    }
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [loc.location[1], loc.location[0]],
      },
      properties: {
        info: info,
        title: i18n.t('Site')(),
        iconType: 'site',
      },
    } as GeoJSON;
  });
  const sitesShapes = locations
    .map((loc) => {
      if (!loc.site_area) {
        return null;
      }
      return {
        type: 'Feature',
        geometry: loc.site_area.geometry,
        properties: {
          info: [{ title: i18n.t('Site')(), value: translate(loc.site_name_json) }],
          title: i18n.t('Site shape')(),
          iconType: 'site',
          id: loc.site_id,
        },
      } as GeoJSON;
    })
    .filter((shape) => shape !== null);
  return sitesLocations.concat(sitesShapes);
}
