import page from 'page';
import * as ko from 'knockout';
import i18n from '../i18n';

import { ListRequestParams, RemoveResult } from '../api/request';
import { FormSelectSearchConfiguration } from './form_select_search';
import { Action } from './basic_widgets';
import * as utils from '../utils';
import { I18nText, translate } from '../i18n_text';
import { MaybeKO } from '../utils/ko_utils';
import { logError } from '../error_logging';
import { FilterDelegate, getFilterObservable } from './list_filters';
import { session } from '../session';
import { removeDialog } from './remove_dialog';

let template = require('raw-loader!../../templates/components/list_loader.html').default;

type FilterValue = string | Date;

export interface ListFilter {
  name: string;
  slug: string;
  type: 'text' | 'numeric' | 'date' | 'select' | 'choices';

  config?: FormSelectSearchConfiguration<{
    id?: string | KnockoutObservable<string>;
  }>;
  choices?: { name: string; value: string }[];

  value?: KnockoutObservable<FilterValue>;
  minValue?: KnockoutObservable<FilterValue>;
  maxValue?: KnockoutObservable<FilterValue>;
}

export function makeYesNoFilter(title: string, obs: KnockoutObservable<'yes' | 'no' | 'all'>): ListFilter {
  return {
    name: title,
    slug: '',
    type: 'choices',
    choices: [
      { name: i18n.t('All')(), value: 'all' },
      { name: i18n.t('Yes')(), value: 'yes' },
      { name: i18n.t('No')(), value: 'no' },
    ],
    value: obs,
  };
}

export interface ListLoaderDelegate<TData, T = TData> {
  pageSize?: number;

  /**
   * Specifies if the 'items per load' select input can be visible.
   */
  allowItemsPerLoadDisplay?: boolean;

  fetch(params: ListRequestParams): Promise<TData[]>;
  instantiate(data: TData): T;
  getEditUrl?(entity: T): string;
  remove?(id: string): Promise<RemoveResult>;
  canRemove?(entity: T): boolean;

  onReady?(loader: ListLoader<TData, T>): void;
  onRefresh?(items: T[]): void;
  getName?(entity: T): string;
  getActions?(entity: T): Action[];
  sortBys?: KnockoutObservableArray<KnockoutObservable<string>>;
  sortingOptions?: { name: string; value: string }[];
  allowEmptySortBy?: boolean;
  filters?: ListFilter[];
  newFilters?: FilterDelegate[];
  allowEditFilters?: boolean;
  noItemsText?: string;
}

export const NO_PAGINATION = 0;
const PAGE_SIZE_OPTIONS = [25, 50, 100, 250];

type OnItemsReceiveCallback<T> = (items: T[]) => void;

export class ListLoader<
  TData,
  T extends {
    id?: MaybeKO<string>;
    name_json?: I18nText;
    nameJson?: KnockoutObservable<I18nText>;
    name?: KnockoutObservable<string>;
  } = TData
> {
  loading = ko.observable(true);
  showRetry = ko.observable(false);
  items = ko.observableArray<T>();
  itemsPerLoadOptions = ko.observableArray(PAGE_SIZE_OPTIONS.map((n) => ({ value: n.toString() })));
  itemsPerLoadRaw = ko.observable(this.itemsPerLoadOptions()[2].value);
  itemsPerLoad = ko.pureComputed(() => parseInt(this.itemsPerLoadRaw()));
  visibleItemsLimit = ko.observable(this.itemsPerLoad());
  hasMore = ko.observable(false);
  focusedFilter = ko.observable<ListFilter>(null);

  private delegate: ListLoaderDelegate<TData, T>;
  private lastRequestId = 0;
  private subscriptions: KnockoutSubscription[] = [];
  private sortBySubscriptions: KnockoutSubscription[] = [];

  constructor(params: { delegate: ListLoaderDelegate<TData, T> }) {
    this.delegate = params.delegate;

    if (this.delegate.sortBys) {
      this.subscribeSortBys();
      this.subscriptions.push(this.delegate.sortBys.subscribe(this.onSortBysChanged));
    }
    if (this.delegate.filters) {
      for (let filter of this.delegate.filters) {
        let refresh =
          filter.type === 'select' || filter.type === 'choices' ? this.refreshAndBlur : this.refresh;

        if (filter.config && filter.config.entity) {
          this.subscriptions.push(filter.config.entity.subscribe(refresh));
        }
        if (filter.value) {
          this.subscriptions.push(filter.value.subscribe(refresh));
        }
        if (filter.minValue) {
          this.subscriptions.push(filter.minValue.subscribe(refresh));
        }
        if (filter.maxValue) {
          this.subscriptions.push(filter.maxValue.subscribe(refresh));
        }
      }
    }
    if (this.delegate.newFilters) {
      for (let filter of this.delegate.newFilters) {
        this.subscriptions.push(getFilterObservable(filter).subscribe(this.refresh));
      }
    }

    if (this.delegate.onReady) {
      this.delegate.onReady(this);
    }
    this.loadMore();
  }

  isFilterDefined = ko.pureComputed(() => {
    for (let filter of this.delegate.filters || []) {
      if (this.isFilterSet(filter)) {
        return true;
      }
    }

    for (let filter of this.delegate.newFilters || []) {
      if (getFilterObservable(filter)()) {
        return true;
      }
    }

    return false;
  });

  noItemsText = ko.pureComputed<string>(() => {
    return this.delegate.noItemsText || i18n.t('No items defined.')();
  });

  dispose() {
    for (let subscription of this.subscriptions) {
      subscription.dispose();
    }
    this.disposeSortBys();
  }

  private disposeSortBys() {
    for (let subscription of this.sortBySubscriptions) {
      subscription.dispose();
    }
    this.sortBySubscriptions = [];
  }

  private subscribeSortBys() {
    this.disposeSortBys();
    for (let sortBy of this.delegate.sortBys()) {
      this.sortBySubscriptions.push(sortBy.subscribe(this.refresh));
    }
  }

  private onSortBysChanged = () => {
    this.subscribeSortBys();
    this.refresh();
  };

  forceLoad = (onItemsReceived?: OnItemsReceiveCallback<T>) => {
    this.items([]);
    this.hasMore(false);
    this.loadMore(onItemsReceived);
  };
  refresh = () => {
    this.items([]);
    this.hasMore(false);
    this.loadMore();
  };

  private refreshAndBlur = () => {
    this.refresh();
    this.hideFilter();
  };

  loadMore = (onItemsReceived?: OnItemsReceiveCallback<T>) => {
    let pageSize = this.delegate.pageSize === undefined ? this.itemsPerLoad() : this.delegate.pageSize;

    let requestId = ++this.lastRequestId;
    let items = this.items();

    if (this.items().length == this.visibleItemsLimit() || this.items().length > this.visibleItemsLimit()) {
      this.visibleItemsLimit(this.itemsPerLoad() + this.visibleItemsLimit());
    }

    if (!this.hasMore() && this.items().length > 0) {
      return;
    }

    this.showRetry(false);
    this.loading(true);
    this.delegate
      .fetch({ limit: pageSize, offset: this.items().length })
      .then((itemsData) => {
        if (requestId !== this.lastRequestId) {
          return;
        }

        let newItems = itemsData.map((itemData: TData) => this.delegate.instantiate(itemData));
        if (pageSize !== NO_PAGINATION && newItems.length == pageSize) {
          this.hasMore(true);
        } else {
          this.hasMore(false);
        }

        this.items(items.concat(newItems));
      })
      .then(() => {
        this.loading(false);

        if (onItemsReceived) {
          onItemsReceived(this.items());
        }
        if (this.delegate.onRefresh) {
          this.delegate.onRefresh(this.items());
        }
      })
      .catch((e) => {
        logError(undefined, e);

        this.showRetry(items.length === 0);
        this.loading(false);
      });
  };

  confirmRemove = (item: T) => {
    let name: string | I18nText = this.delegate.getName && this.delegate.getName(item);
    name = name || translate(item.name_json);
    name = name || (item.nameJson && translate(item.nameJson()));
    name = name || (item.name && ko.unwrap(item.name));

    if (!item.id || !name) {
      return;
    }

    removeDialog(name, [], () => this.delegate.remove(ko.unwrap(item.id))).then(() => {
      this.items.remove(item);
    });
  };

  getActions = (entity: T): Action[] => {
    let actions: Action[] = [];

    if (this.delegate.getActions) {
      actions = this.delegate.getActions(entity);
    }

    let editUrl = this.delegate.getEditUrl && this.delegate.getEditUrl(entity);
    if (editUrl) {
      actions.push({
        icon: 'edit',
        title: i18n.t('Edit')(),
        cssClass: '',
        onClick: () => {
          page(session.toTenantPath(editUrl));
        },
      });
    }

    if (this.delegate.canRemove(entity)) {
      actions.push({
        icon: 'delete_outline',
        title: i18n.t('Remove')(),
        cssClass: '',
        onClick: () => {
          this.confirmRemove(entity);
        },
      });
    }

    return actions;
  };

  formatFilterValue = (filter: ListFilter) => {
    let any = i18n.t('Any')();
    let notSet = i18n.t('Not set')();

    if (filter.config && filter.config.entity) {
      if (filter.config.entity()) {
        return filter.config.getSummaryName(filter.config.entity());
      } else {
        return notSet;
      }
    }

    if (filter.value) {
      let value = filter.value();

      if (value) {
        if (filter.choices) {
          for (let choice of filter.choices) {
            if (choice.value === value) {
              return choice.name;
            }
          }
        }

        return utils.tryFormatDate(value);
      } else {
        return notSet;
      }
    }

    let min: string;
    let max: string;
    let hasMinOrMax = false;
    if (filter.minValue && filter.minValue()) {
      min = utils.tryFormatDate(<string | Date>filter.minValue());
      hasMinOrMax = true;
    } else {
      min = any;
    }
    if (filter.maxValue && filter.maxValue()) {
      max = utils.tryFormatDate(<string | Date>filter.maxValue());
      hasMinOrMax = true;
    } else {
      max = any;
    }

    return hasMinOrMax ? min + ' - ' + max : notSet;
  };

  isFilterSet = (filter: ListFilter) => {
    return (
      (filter.config && filter.config.entity && filter.config.entity()) ||
      (filter.value && filter.value()) ||
      (filter.minValue && filter.minValue()) ||
      (filter.maxValue && filter.maxValue())
    );
  };

  showFilter = (filter: ListFilter, event: Event) => {
    event.stopPropagation();

    this.focusedFilter(filter);

    if (filter.type !== 'date') {
      setTimeout(() => {
        $(event.target).parent().next().find('input').first().focus();
      }, 0);
    }
  };

  keepFilter = (_: {}, event: Event) => {
    event.stopPropagation();
  };

  hideFilter = () => {
    this.focusedFilter(null);

    return true;
  };
}

export class ItemSelectionTracker<T> {
  selectedItems = ko.observableArray<T>();

  allVisibleSelected = ko.pureComputed({
    read: () => {
      return this.selectedItems().length > 0 && this.selectedItems().length === this.items().length;
    },
    write: (value) => {
      this.selectedItems(value ? this.items().slice(0) : []);
    },
  });

  allSelectAttempted = ko.observable(false);

  /**
   * Whether all items are selected, including those that are not visible due to pagination.
   */
  allSelected = ko.pureComputed(() => {
    const result = this.allVisibleSelected() && this.allSelectAttempted();
    if (!result) this.allSelectAttempted(false);
    return result;
  });

  /**
   * Whether some items are selected, but not all.
   **/
  hasPartialSelection = ko.pureComputed(() => {
    return (
      this.selectedItems().length > 0 &&
      this.selectedItems().length < this.items().length &&
      !this.allVisibleSelected()
    );
  });

  /**
   * Total number of items of type T. Can include items not yet loaded.
   */
  total = ko.observable<number | null>();

  selectedCount = ko.pureComputed(() => {
    if (this.allSelected() && this.total()) {
      return this.total();
    } else {
      return this.selectedItems().length;
    }
  });

  constructor(private items: KnockoutObservable<T[]>) {}

  selectAll = () => {
    this.allSelectAttempted(true);
  };

  clear = () => {
    this.selectedItems([]);
    this.allSelectAttempted(false);
  };
}

ko.components.register('list-loader', {
  viewModel: ListLoader,
  template: template,
});
