import * as ko from 'knockout';
import i18n from '../../i18n';

import { BaseTrialStep } from './base';
import { WizardController } from '../../screens/trial_wizard';
import { session } from '../../session';
import { TrialState, getTrialStateLabel } from '../../models/TrialState';

let template =
  require('raw-loader!../../../templates/components/trial_wizard/trial_activation.html').default;

class TrialActivation extends BaseTrialStep {
  // @ts-ignore
  private title = session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial())
    ? i18n.t('Step 9 - Activation')()
    : i18n.t('Step 8 - Activation')();

  private initialState = ko.observable<TrialState>(TrialState.Draft); // the last known saved state

  // the currently selected state, might be not saved yet
  state = ko.computed<TrialState>({
    read: () => {
      return this.trialWizard().trial().state();
    },
    write: (value: string) => {
      this.trialWizard()
        .trial()
        .state(value as TrialState);
      this.showError(false);
    },
  });
  template = ko.pureComputed(() => {
    let trial = this.trialWizard().trial();
    return trial ? trial.template : false;
  });

  staffAssignmentPageUrl = ko.pureComputed(() => {
    return session.toTenantPath(this.trialWizard().trial().assignStaffUrl());
  });

  showError = ko.observable<boolean>(false);

  trialStates = ko.pureComputed(() => {
    const draftStates = [TrialState.Draft, TrialState.Preview, TrialState.Approved];
    const isDraftState = draftStates.includes(this.initialState());

    const states = [TrialState.Archived];

    if (!this.template()) {
      states.unshift(TrialState.Active);

      if (this.initialState() === TrialState.Completed) {
        states.push(TrialState.Completed);
      }

      if (this.initialState() === TrialState.Completed || this.initialState() === TrialState.DataValidated) {
        states.push(TrialState.DataValidated);
      }

      if (
        isDraftState ||
        this.initialState() === TrialState.Active ||
        this.initialState() === TrialState.Canceled
      ) {
        states.push(TrialState.Canceled);
      }
    }

    // templates can go archived -> draft
    if (this.template() || isDraftState) {
      states.unshift(TrialState.Draft, TrialState.Preview);
    }
    if (this.template() && isDraftState) {
      states.splice(states.length - 1, 0, TrialState.Approved);
    }

    return states.map((state) => ({
      name: getTrialStateLabel(state),
      value: state,
    }));
  });

  constructor(params: { controller: WizardController }) {
    super(params);

    let trial = this.trialWizard().trial();
    if (trial.id()) {
      this.initialState(trial.state());
    }

    this.ready(true);
  }

  dispose() {
    this.state.dispose();
  }

  isStateEnabled(state: { value: string }): boolean {
    const trial = this.trialWizard().trial();
    const currentTrialState = trial.state();
    const currentUser = this.trialWizard().userData;

    if (currentUser.role === 'admin') {
      return true;
    }

    if (trial.template) {
      if (currentUser.role === 'template_editor') {
        return trial.owners().some((owner) => owner.id === currentUser.id);
      } else if (
        currentTrialState === TrialState.Approved &&
        state.value === TrialState.Archived &&
        (currentUser.role === 'editor' || currentUser.role === 'manager') &&
        trial.owners().some((owner) => owner.id === currentUser.id)
      ) {
        return true;
      }
    }

    if (
      [TrialState.Draft, TrialState.Preview, TrialState.Active].includes(state.value as TrialState) &&
      this.allowEditAnyLimited()
    ) {
      return true;
    }

    return this.allowEditAny() && !(state.value === TrialState.Preview && trial && !trial.crop());
  }

  hasErrors(): boolean {
    return this.showError();
  }

  applyLocalValidation(): boolean {
    let isValid = !!this.state();

    this.showError(!isValid);

    return isValid;
  }

  didSave() {
    this.initialState(this.state());
  }
}

ko.components.register('trial-activation', {
  viewModel: TrialActivation,
  template: template,
});
