import { tryFormatDate, findById } from '../utils';
import { OverviewValueType, ValidationData } from '../api/dashboard';
import { MultiPicturesModel } from '../data_entry/edit/data_entry_edit_model';

/**
 * Formats a raw observation value to a string user-friendly representation.
 * @param {OverviewValueType} type - The type of the value.
 * @param {ValidationData} validation - Validation rules for the value.
 * @param {*} value - The raw value to format.
 * @returns {string}
 */
export function formatValue(type: OverviewValueType, validation: ValidationData, value: {}): string {
  if (value === undefined || value === null || value === '') {
    return '';
  }
  if (type === 'multi_pictures' && getMultiPicturesUrls(value).length === 0) {
    return '';
  }

  if (type === 'date' || type === 'date_derived') {
    return tryFormatDate(value as string);
  }
  if (type === 'integer' || type === 'decimal' || type === 'number_derived') {
    return value.toLocaleString();
  }
  if (type === 'choice') {
    const choice = findById(validation?.options ?? [], value.toString());
    if (validation.rating) {
      return choice?.value?.toLocaleString() ?? '';
    } else {
      return choice?.name ?? '';
    }
  }
  if (type === 'string' || type === 'string_long' || type === 'barcode') {
    return value as string;
  }

  return 'OK';
}

/**
 * Extracts the URLs of an observation value of type multi_pictures.
 * @param {*} value - The multi_pictures value.
 * @returns {string[]}
 */
export function getMultiPicturesUrls(value: {}): string[] {
  if (Array.isArray(value)) {
    return new MultiPicturesModel(value).urls();
  } else {
    return [];
  }
}
