import * as ko from 'knockout';

import i18n from '../i18n';
import { ListRequestParams } from '../api/request';
import { ListLoader, ListLoaderDelegate } from '../components/list_loader';
import * as mmApi from '../api/measurement_metas';
import * as mmSuggestionsApi from '../api/v2/measurement_meta_suggestions';
import * as cropsApi from '../api/crops';
import * as dimensionsApi from '../api/dimensions';
import * as traitCategoriesApi from '../api/trait_categories';
import * as mmTagsApi from '../api/measurement_meta_tags';
import { BaseLoadingScreen } from './base_loading_screen';
import { deflateList } from '../api/serialization';
import { updateLocationWithQueryString, asArray, downloadBlob } from '../utils';
import { ATTRIBUTE_TYPES_WITH_DDM } from '../models/measurement_meta';
import { FilterDelegate } from '../components/list_filters';
import { canEditMMLibrary } from '../permissions';
import { session } from '../session';
import { Action } from '../components/basic_widgets';
import { openMeasurementLibraryTrials } from '../components/measurement_library_trials';
import { ListHeaderAction } from '../components/list_header';
import { MeasurementMetaData } from '../api/datasets';

let template = require('raw-loader!../../templates/measurement_meta_library.html').default;

class MeasurementMetaLibrary
  extends BaseLoadingScreen
  implements ListLoaderDelegate<mmApi.MeasurementMetaLibraryData, mmApi.MeasurementMetaLibraryData>
{
  private listLoader: ListLoader<mmApi.MeasurementMetaLibraryData, mmApi.MeasurementMetaLibraryData>;
  exporting = ko.observable(false);
  management: boolean;
  showDriver = session.tenant().tpp_enabled;
  canEdit = canEditMMLibrary();

  private nameFilter = ko.observable('');
  private cropFilter = ko.observableArray<dimensionsApi.DimensionData>(null);
  private traitCategoryFilter = ko.observableArray<traitCategoriesApi.TraitCategoryData>(null);
  private tagFilter = ko.observableArray<mmTagsApi.MeasurementMetaTagData>(null);
  newFilters: FilterDelegate[] = [
    { title: i18n.t('Crop')(), entities: this.cropFilter, list: cropsApi.list },
  ];
  searchPlaceholder = i18n.t('Search trait')();
  // TODO: add boolean filter: type derived or not

  listActions = ko.observableArray<ListHeaderAction>();

  constructor(params: {
    management: boolean;
    filters: {
      name_prefix: string;
      crop_ids: string;
      trait_category_ids: string;
      mm_tag_ids: string;
    };
  }) {
    super();

    this.management = params.management;

    if (this.canEdit) {
      this.listActions.push({
        title: i18n.t('Add')(),
        icon: 'add_circle',
        onClick: () => {
          const url = this.management ? '/management_traits_library/new/' : '/library/traits/new/';
          window.location.href = session.toTenantPath(url);
        },
        tooltipTitle: this.management ? i18n.t('Add management trait')() : i18n.t('Add trait')(),
      });

      mmSuggestionsApi.count().then((response) => {
        const count = response.count;
        if (count > 0) {
          this.listActions.push({
            title: i18n.t('Review')(),
            chipText: count.toString(),
            icon: 'rate_review',
            href: '/library/traits/review/',
            tooltipTitle: i18n.t('Review {{count}} traits', { count })(),
          });
        }
      });
    }

    if (!params.management) {
      this.listActions.push({
        title: i18n.t('Export')(),
        icon: 'file_download',
        onClick: this.exportLibrary,
        loading: this.exporting,
      });
    }
    if (!params.management) {
      this.newFilters.push({
        title: i18n.t(['trait_category_title', 'Trait Category'])(),
        entities: this.traitCategoryFilter,
        list: traitCategoriesApi.list,
      });
      this.newFilters.push({
        title: i18n.t(['trait_tags_title', 'Trait Tags'])(),
        entities: this.tagFilter,
        list: mmTagsApi.list,
      });
    }

    this.nameFilter(params.filters.name_prefix || '');
    this.nameFilter = this.nameFilter.extend({ throttle: 300 });

    let cropIds = asArray(params.filters.crop_ids);
    let traitCategoryIds = asArray(params.filters.trait_category_ids);
    let tagIds = asArray(params.filters.mm_tag_ids);
    let cropPromise = cropIds.length > 0 ? cropsApi.list({ ids: cropIds }) : undefined;
    let traitCategoryPromise =
      traitCategoryIds.length > 0 ? traitCategoriesApi.list({ ids: traitCategoryIds }) : undefined;
    let tagPromise = tagIds.length > 0 ? mmTagsApi.list({ ids: tagIds }) : undefined;
    let promise = Promise.all([cropPromise, traitCategoryPromise, tagPromise]).then(
      ([crop, traitCategory, tags]) => {
        this.cropFilter((crop as cropsApi.CropData[]) || []);
        this.traitCategoryFilter((traitCategory as traitCategoriesApi.TraitCategoryData[]) || []);
        this.tagFilter((tags as mmTagsApi.MeasurementMetaTagData[]) || []);
      }
    );
    this.loadedAfter(promise);
  }

  onReady(loader: ListLoader<mmApi.MeasurementMetaLibraryData, mmApi.MeasurementMetaLibraryData>) {
    this.listLoader = loader;
    this.nameFilter.subscribe(() => this.listLoader.forceLoad());
  }

  fetch(params: ListRequestParams) {
    let filter = {
      name_prefix: this.nameFilter(),
      crop_ids: deflateList(this.cropFilter),
      trait_category_ids: deflateList(this.traitCategoryFilter),
      mm_tag_ids: deflateList(this.tagFilter),
    };
    updateLocationWithQueryString(filter);

    return mmApi.list({
      only_for: null,
      management: this.management,
      ...filter,
      ...params,
    });
  }

  instantiate(data: mmApi.MeasurementMetaLibraryData) {
    return data;
  }

  remove(id: string) {
    return mmApi.remove(id);
  }

  canRemove(mm: mmApi.MeasurementMetaLibraryData) {
    return this.canEdit;
  }

  getEditPageUrl(id: string) {
    return session.toTenantPath(
      this.management ? `/management_traits_library/${id}/` : `/library/traits/${id}/`
    )
  }
  navigateToEditPage(id: string) {
    const url = this.getEditPageUrl(id);
    window.location.href = url;
  }

  typeName(mm: mmApi.MeasurementMetaLibraryData) {
    return typeName(mm);
  }

  /**
   * Generates a string representation for min/max values for numeric and date traits.
   * @param measurementMeta measurement meta data.
   * @returns A string in format '{min} - {max}'. Empty string if there are no min/max limits.
   */
  minMaxValuesRepresentation(measurementMeta: mmApi.MeasurementMetaLibraryData) {
    let min, max;
    if (measurementMeta.type == 'date') {
      if (measurementMeta.validation.date_min_value != null) {
        min = new Date(measurementMeta.validation.date_min_value).toLocaleDateString();
      }
      if (measurementMeta.validation.date_max_value != null) {
        max = new Date(measurementMeta.validation.date_max_value).toLocaleDateString();
      }
    } else if (measurementMeta.type == 'decimal') {
      if (measurementMeta.validation.number_min_value != null) {
        min = measurementMeta.validation.number_min_value.toString();
      }
      if (measurementMeta.validation.number_max_value != null) {
        max = measurementMeta.validation.number_max_value.toString();
      }
    }

    if (min && max) {
      return `[${min}, ${max}]`;
    } else if (min) {
      return `≥ ${min}`;
    } else if (max) {
      return `≤ ${max}`;
    }
    return '';
  }

  unitName(mm: mmApi.MeasurementMetaLibraryData) {
    return unitName(mm);
  }

  getActions(mm: mmApi.MeasurementMetaLibraryData): Action[] {
    return [
      {
        icon: 'assignment',
        title: i18n.t('Trials')(),
        cssClass: '',
        onClick: () => openMeasurementLibraryTrials(mm.id),
      },
    ];
  }

  exportLibrary = async () => {
    this.exporting(true);

    try {
      const data = await mmApi.exportLibrary({
        name_prefix: this.nameFilter(),
        crop_ids: deflateList(this.cropFilter),
        trait_category_ids: deflateList(this.traitCategoryFilter),
        only_for: null,
        management: this.management,
      });
      this.exporting(false);
      downloadBlob(data, 'traits_library.xlsx');
    } finally {
      this.exporting(false);
    }
  };
}

export function typeName(mm: mmApi.SelectedTraitData): string {
  if (mm.normalize) {
    if (mm.type === 'date') {
      return i18n.t('Days')();
    } else if (mm.trait_unit_num && mm.trait_unit_den) {
      return mm.trait_unit_num.description;
    }
  }

  for (let attr of ATTRIBUTE_TYPES_WITH_DDM) {
    if (attr.value === mm.type) {
      if (attr.value === 'choice' && mm.mt_rating) {
        return `${attr.name()}, ${i18n.t('Rating')()}`;
      }
      return attr.name();
    }
  }

  return '';
}
export function unitName(mm: MeasurementMetaData): string {
  if (mm.normalize) {
    if (mm.type === 'date') {
      return i18n.t('Days')();
    } else if (mm.trait_unit_num && mm.trait_unit_den) {
      return mm.trait_unit_den.description;
    }
  }

  if (mm.type == 'derived' && mm.unit_formula) {
    return mm.unit_formula;
  }

  if (mm.unit) {
    return mm.unit.description;
  }

  return '';
}

export let measurementMetaLibrary = {
  name: 'measurement-meta-library',
  viewModel: MeasurementMetaLibrary,
  template: template,
};

ko.components.register(measurementMetaLibrary.name, measurementMetaLibrary);
