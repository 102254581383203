import * as ko from 'knockout';

import i18n from '../i18n';
import { ListRequestParams } from '../api/request';
import { ListLoader, ListLoaderDelegate } from '../components/list_loader';
import * as mmSuggestionsApi from '../api/v2/measurement_meta_suggestions';
import * as cropsApi from '../api/crops';
import * as dimensionsApi from '../api/dimensions';
import * as traitCategoriesApi from '../api/trait_categories';
import * as mmTagsApi from '../api/measurement_meta_tags';
import { BaseLoadingScreen } from './base_loading_screen';
import { deflateList } from '../api/serialization';
import { updateLocationWithQueryString, asArray } from '../utils';
import { FilterDelegate } from '../components/list_filters';
import { canEditMMLibrary } from '../permissions';
import { ListHeaderAction } from '../components/list_header';
import { session } from '../session';

let template = require('raw-loader!../../templates/measurement_meta_library_review_list.html').default;

class MeasurementMetaLibraryReviewList
  extends BaseLoadingScreen
  implements
    ListLoaderDelegate<
      mmSuggestionsApi.MeasurementMetaSuggestionData,
      mmSuggestionsApi.MeasurementMetaSuggestionData
    >
{
  private listLoader: ListLoader<
    mmSuggestionsApi.MeasurementMetaSuggestionData,
    mmSuggestionsApi.MeasurementMetaSuggestionData
  >;
  canEdit = canEditMMLibrary();

  private nameFilter = ko.observable('');
  private cropFilter = ko.observableArray<dimensionsApi.DimensionData>(null);
  private traitCategoryFilter = ko.observableArray<traitCategoriesApi.TraitCategoryData>(null);
  private tagFilter = ko.observableArray<mmTagsApi.MeasurementMetaTagData>(null);
  newFilters: FilterDelegate[] = [
    { title: i18n.t('Crop')(), entities: this.cropFilter, list: cropsApi.list },
    {
      title: i18n.t(['trait_category_title', 'Trait Category'])(),
      entities: this.traitCategoryFilter,
      list: traitCategoriesApi.list,
    },
    {
      title: i18n.t(['trait_tags_title', 'Trait Tags'])(),
      entities: this.tagFilter,
      list: mmTagsApi.list,
    },
  ];
  searchPlaceholder = i18n.t('Search trait')();

  listActions: ListHeaderAction[];

  constructor(params: {
    management: boolean;
    filters: {
      name_prefix: string;
      crop_ids: string;
      trait_category_ids: string;
      mm_tag_ids: string;
    };
  }) {
    super();

    this.listActions = [];

    this.nameFilter(params.filters.name_prefix || '');
    this.nameFilter = this.nameFilter.extend({ throttle: 300 });

    let cropIds = asArray(params.filters.crop_ids);
    let traitCategoryIds = asArray(params.filters.trait_category_ids);
    let tagIds = asArray(params.filters.mm_tag_ids);
    let cropPromise = cropIds.length > 0 ? cropsApi.list({ ids: cropIds }) : undefined;
    let traitCategoryPromise =
      traitCategoryIds.length > 0 ? traitCategoriesApi.list({ ids: traitCategoryIds }) : undefined;
    let tagPromise = tagIds.length > 0 ? mmTagsApi.list({ ids: tagIds }) : undefined;
    let promise = Promise.all([cropPromise, traitCategoryPromise, tagPromise]).then(
      ([crop, traitCategory, tags]) => {
        this.cropFilter((crop as cropsApi.CropData[]) || []);
        this.traitCategoryFilter((traitCategory as traitCategoriesApi.TraitCategoryData[]) || []);
        this.tagFilter((tags as mmTagsApi.MeasurementMetaTagData[]) || []);
      }
    );
    this.loadedAfter(promise);
  }

  onReady(
    loader: ListLoader<
      mmSuggestionsApi.MeasurementMetaSuggestionData,
      mmSuggestionsApi.MeasurementMetaSuggestionData
    >
  ) {
    this.listLoader = loader;
    this.nameFilter.subscribe(() => this.listLoader.forceLoad());
  }

  fetch(params: ListRequestParams) {
    let filter = {
      name_prefix: this.nameFilter(),
      crop_ids: deflateList(this.cropFilter),
      trait_category_ids: deflateList(this.traitCategoryFilter),
      mm_tag_ids: deflateList(this.tagFilter),
    };
    updateLocationWithQueryString(filter);

    return mmSuggestionsApi.list();
  }

  instantiate(data: mmSuggestionsApi.MeasurementMetaSuggestionData) {
    return data;
  }

  navigateToReviewPage(id: string) {
    const url = `/library/traits/review/${id}/`;
    window.location.href = session.toTenantPath(url);
  }
}

export let measurementMetaLibraryReviewList = {
  name: 'measurement-meta-library-review-list',
  viewModel: MeasurementMetaLibraryReviewList,
  template: template,
};

ko.components.register(measurementMetaLibraryReviewList.name, measurementMetaLibraryReviewList);
