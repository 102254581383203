import { request } from '../request';

import i18n from '../../i18n';

export interface AiQueryRequest {
  query: string;
  query_context: {
    [key: string]: any;
  };
}

export interface AiQueryResponse {
  answer: string;
}

function instanceOfAiQueryResponse(object: any): object is AiQueryResponse {
  return 'answer' in object;
}

/**
 * Processes a natural language query about trial data using AI
 *
 * @param data Query and context for the AI to process
 * @returns Promise with the AI response
 */
export async function processAiQuery(data: AiQueryRequest): Promise<AiQueryResponse> {
  const response = await request<AiQueryResponse>('POST', '/api/v2/ai/query/', data, {
    injectTenant: true,
    version: 2,
    skipErrorResponseHandling: true,
  });

  if (!instanceOfAiQueryResponse(response)) {
    // Response is empty when an error occurs. That is handled by the request function.
    return {
      answer: i18n.t(
        "I'm sorry, I encountered an error while processing your request. Please try again later."
      )(),
    };
  }

  return response;
}
