import { OverviewData, OverviewValueType, ValidationData } from '../api/dashboard';
import { formatValue } from '../components/overview_value';
import { OverviewSparseData } from '../data_entry/edit/data_entry_edit_model';
import i18n from '../i18n';
import { translate } from '../i18n_text';

export class OverviewExporter {
  private table: HTMLTableElement;
  private overviewData: OverviewData;
  private overviewSparseData: OverviewSparseData;

  constructor(table: HTMLTableElement, overviewData: OverviewData, overviewSparseData: OverviewSparseData) {
    this.table = table;
    this.overviewData = overviewData;
    this.overviewSparseData = overviewSparseData;
  }

  public exportToJson(): {
    header: string[];
    rows: string[][];
  } {
    const header = this.generateHeader();
    const rows = this.generateRows();
    return { header, rows };
  }

  private generateHeader(): string[] {
    const baseHeaders = this.overviewData.header_groups.map((headerGroup) => headerGroup.title);

    const plotDimensionHeaders = [
      i18n.t('Plot name')(),
      i18n.t('Treatment')(),
      i18n.t('Test subjects / Dimensions')(),
      i18n.t('Site')(),
      i18n.t('Replication')(),
    ];
    const observationValueHeader = i18n.t('Trait value')();

    return [...baseHeaders, ...plotDimensionHeaders, observationValueHeader];
  }

  private getValueAtColumn(row: HTMLTableRowElement, column: number): string {
    const cells = Array.from(row.querySelectorAll('th'));
    let currentIndex = 0;

    for (const cell of cells) {
      const colSpan = parseInt(cell.getAttribute('colspan') || '1', 10);

      // Check if this cell covers the target column
      if (column >= currentIndex && column < currentIndex + colSpan) {
        return cell.querySelector('span')?.textContent?.trim() || cell?.textContent?.trim() || '';
      }

      currentIndex += colSpan;
    }

    return ''; // Return empty string if column is not found
  }

  private getBaseHeadersAtColumn(column: number): string[] {
    const headerRows = this.table.querySelectorAll<HTMLTableRowElement>('thead tr');
    const headerValues = Array.from(headerRows).map((row) => this.getValueAtColumn(row, column));

    return headerValues;
  }

  private getTraitTypeAt(column: number): OverviewValueType {
    return this.overviewData.value_types[column];
  }

  private getTraitValidationAt(column: number): ValidationData {
    return this.overviewData.validations[this.overviewData.mm_ids[column]];
  }

  private getTraitValueAt(row: number, column: number): string {
    return formatValue(
      this.getTraitTypeAt(column),
      this.getTraitValidationAt(column),
      this.overviewSparseData.valueAt(this.overviewSparseData.values, row, column)
    );
  }

  private generateRows(): string[][] {
    const plotDimensions = this.overviewData.rows.map((row) => {
      const [replication, site, ...test_subjects] = row.plot_dimensions.map(translate).reverse();
      return [row.plot_name, row.plot_treatment_name, test_subjects.join(', '), site, replication];
    });

    // For each trait value, add a new row repeating the plot dimensions
    return this.overviewData.rows.reduce<string[][]>(
      (acc: string[][], _, row_index) =>
        acc.concat(
          this.overviewData.mm_ids.map((_, column_index) => {
            return [
              ...this.getBaseHeadersAtColumn(column_index + 1),
              ...plotDimensions[row_index],
              this.getTraitValueAt(row_index, column_index),
            ];
          })
        ),
      []
    );
  }
}
