import { requestWithStatus } from './base_request';

export interface TenantData {
  name: string;
  slug: string;
  is_free_trial: boolean;
  is_sfsa_partner: boolean;
  expiration_date: string;
  is_expired: boolean;
  expirationTitle?: string;
  country_and_region_mandatory_for_site: boolean;
  admin_only_data_editing_mode: boolean;
  weather_enabled: boolean;
  advanced_weather_enabled: boolean;
  prefer_embedded_mt: boolean;
  web_data_entry: boolean;
  allow_password_login: boolean;
  tpp_enabled: boolean;
  observation_history_enabled: boolean;
  observation_group_tab_enabled: boolean;
  barcode_scan_action_enabled: boolean;
  manual_trials_enabled: boolean;
  map_visualization_enabled: boolean;
  data_warehouse_access_enabled: boolean;
  name_override_enabled: boolean;
  treatment_templates_enabled: boolean;
  treatment_management_enabled: boolean;
  treatment_management_enabled_at: string;
  restricted_manager_enabled: boolean;
  custom_reports_enabled: boolean;
  limit_traits_to_dimensions_enabled: boolean;
  mobile_derived_traits_enabled: boolean;
  push_notifications_enabled: boolean;
  reports_enabled: boolean;
  custom_permissions_enabled: boolean;
  ai_enabled: boolean;

  role:
    | 'admin'
    | 'read_only_admin'
    | 'staff'
    | 'partner'
    | 'company'
    | 'editor'
    | 'manager'
    | 'restricted_manager'
    | 'template_editor'
    | 'data_entry';
  user_id: string;
  user_type: string;
  country_roles: { [country_id: string]: 'head' | 'editor' | 'viewer' };
  producers: { id: string; country_id: string }[];
  buyers: { id: string; country_id: string }[];
  permissions: string[];
}

export interface TenantPublicData {
  id: string;
  firebase_id: string;
  slug: string;
  name: string;
}

export function authorizations(
  authToken: string,
  loginAttempt: boolean
): Promise<{ user_email: string; tenants: TenantData[] }> {
  let params = loginAttempt ? '?login=1' : '';
  return requestWithStatus<{ user_email: string; tenants: TenantData[] }>(
    'GET',
    '/api/tenants/' + params,
    authToken
  ).then((response) => {
    if (response.status != 200) {
      return Promise.reject(new Error(response.status.toString()));
    }

    return response.result;
  });
}

export function tenantsByEmail(email: string): Promise<TenantPublicData[]> {
  return requestWithStatus<TenantPublicData[]>('POST', '/api/tenants/list_by_email/', undefined, {
    email,
  }).then((response) => {
    if (response.status != 200) {
      return Promise.reject(new Error(response.status.toString()));
    }
    return response.result;
  });
}

export async function loginEmailPassword(
  email: string,
  password: string
): Promise<{ isValid: boolean; errors: { [field: string]: [string] } }> {
  const response = await requestWithStatus<{}>('POST', '/api/tenants/web_password_sign_in/', undefined, {
    email,
    password,
  });
  if (response.status === 200) {
    return { isValid: true, errors: {} };
  } else {
    return { isValid: false, errors: response.result };
  }
}
